// src/router.js
import { createRouter, createWebHistory } from 'vue-router';
import FishkeepingDemo from './components/FishkeepingDemo.vue';
import SepticaDemo from './components/SepticaDemo.vue';
import ContactPage from './components/ContactPage.vue';
import ArticlesPage from './components/ArticlesPage.vue';
import ArticleDetailPage from '@/components/ArticleDetailPage.vue';
import LandingPage from '@/components/LandingPage.vue';
import DemosPage from '@/components/DemosPage.vue';
import ResumeHelperDemo from '@/components/ResumeHelperDemo.vue';

const routes = [
  { path: '/', component: LandingPage },
  { path: '/fishkeeping-demo', component: FishkeepingDemo },
  { path: '/septica-demo', component: SepticaDemo },
  { path: '/contact', component: ContactPage },
  { path: '/articles', component: ArticlesPage },
  { path: '/demos', component: DemosPage },
  { path: '/resume-helper-demo', component: ResumeHelperDemo },
  {
    path: '/articles/:vanityUrl', 
    name: 'ArticleDetailPage',
    component: ArticleDetailPage,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
