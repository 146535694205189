<template>
  <div class="resume-page container-fluid">
    <button @click="goBack" class="btn btn-secondary mb-3">Back</button>
    <div class="row">
      <!-- Render CV on the left -->
      <div class="col-lg-6">        
        <PreviewResumeComponent :resume-data="resumeData" />
      </div>
      <!-- Render EditResumeContent on the right -->
      <div class="col-lg-6">
        <EditResumeContent :resume-data="resumeData" @update-resume="updateResumeData" />
      </div>
    </div>
  </div>
</template>

<script>
import PreviewResumeComponent from "@/components/ResumeHelper/PreviewResumeComponent.vue";
import EditResumeContent from "@/components/ResumeHelper/EditResumeComponent.vue";
import sampleCV from "@/assets/SampleCV.json";

export default {
  name: "ResumeHelperDemo",
  components: {
    PreviewResumeComponent,
    EditResumeContent,
  },
  data() {
    return {
      resumeData: sampleCV,
    };
  },
  methods: {
    goBack() {
      this.$router.push('/demos');
    },
    updateResumeData(updatedData) {
      this.resumeData = updatedData; // Sync changes from the editor
      console.log(updatedData);
    },
  },
};
</script>

<style>
.resume-page {
  padding: 20px;
}

.content-container {
  max-width: 89vw !important;
}

hr {
  border: 0;
  height: 1px;
  background: linear-gradient(to right, #69caca31, #00006969, #69caca31) !important;
  margin: 10px 0;
}
</style>
