<template>
    <div class="d-flex flex-wrap gap-2">
      <span v-for="item in items" :key="item" class="badge bg-primary">
        {{ item }}
      </span>
    </div>
  </template>
  
  <script>
  export default {
    name: "BadgeListComponent",
    props: {
      items: {
        type: Array,
        required: true,
      },
    },
  };
  </script>
  