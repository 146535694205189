<template>
  <div>
    <h1>Demos</h1>
    <p>This page showcases various demos. Here, you'll find interactive examples and tech showcases. <strong>Click a
        content card to go into details about a specific subject</strong></p>

    <hr />
    <!-- Display each demo item -->
    <div v-if="demos.length > 0">
      <ul>
        <li v-for="(demo, index) in demos" :key="index" @click="goToDemo(demo.url)" style="cursor: pointer;"
          class="light-background-box m10">
          <div class="row align-items-center">
            <!-- Thumbnail -->
            <div v-if="demo.thumbnail" class="col-md-2 mb-2 mb-md-0 d-flex justify-content-center">
              <img :src="demo.thumbnail" :alt="`Thumbnail for ${demo.title}`" class="img-fluid rounded">
            </div>
            <!-- Text Content -->
            <div class="col-md-10">
              <h3 style="cursor: pointer;">{{ demo.title }}</h3>
              <p>{{ demo.description }}</p>
            </div>
          </div>
          <hr />
          <!-- Paragraphs -->
          <div v-for="(paragraph, pIndex) in demo.paragraphs" :key="pIndex">
            <p>{{ paragraph }}</p>
          </div>
        </li>
      </ul>
    </div>

    <p v-else>Loading demos...</p>
  </div>
</template>

<script>
export default {
  name: "DemosPage",
  data() {
    return {
      demos: []
    };
  },
  created() {
    this.loadDemos();
  },
  methods: {
    async loadDemos() {
      try {
        const response = await fetch("/demos.json");
        if (!response.ok) throw new Error("Failed to load demos");
        this.demos = await response.json();
      } catch (error) {
        console.error("Error loading demos:", error);
      }
    },
    goToDemo(url) {
      this.$router.push(url);
    }
  }
};
</script>

<style scoped>
/* Optional styling */
ul {
  list-style-type: none;
  padding: 0;
}
</style>
