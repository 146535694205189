<template>
    <div class="preview-resume light-background-box vh90scrollable">
        <button class="btn mb-4" @click="generatePDF">Download as PDF</button>
        <div class="resume-container" ref="resumeContent">

            <!-- Header with name, job title, and photo -->
            <div class="row">
                <div class="col-md-8">
                    <div class="resume-header d-flex align-items-center">
                        <img v-if="resumeData.userMetadata.imageUrl" :src="resumeData.userMetadata.imageUrl"
                            alt="Profile Image" class="profile-img" />
                        <div>
                            <h1><strong>{{ resumeData.userMetadata.fullName }}</strong></h1>
                            <h3>{{ resumeData.userMetadata.jobTitle }}</h3>
                            <p>
                            <pre>{{ resumeData.userMetadata.summary }}</pre>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="contact-info">
                        <p v-if="resumeData.userMetadata.email">Email: {{ resumeData.userMetadata.email }}</p>
                        <p v-if="resumeData.userMetadata.phone">Phone: {{ resumeData.userMetadata.phone }}</p>
                        <p v-if="resumeData.userMetadata.linkedin">
                            LinkedIn: <a :href="`https://${resumeData.userMetadata.linkedin}`">{{
                                resumeData.userMetadata.linkedin
                            }}</a>
                        </p>
                        <p v-if="resumeData.userMetadata.website">
                            Website: <a :href="resumeData.userMetadata.website">{{ resumeData.userMetadata.website
                                }}</a>
                        </p>
                    </div>
                </div>
            </div>
            <hr />

            <!-- Skills -->
            <div class="skills">
                <h3>Skills Showcase</h3>
                <BadgeListComponent :items="resumeData.skills" />
            </div>
            <hr />

            <!-- Work Experience -->
            <div class="work-experience">
                <h3>Work Experience</h3>
                <div v-for="job in resumeData.workExperience" :key="job.companyName" class="job pagebreakavoidinside">
                    <h4><strong>{{ job.companyName }}</strong> ({{ toDisplayFormat(job.startDate) }} - {{
                        job.isActivePosition ? 'Present' : toDisplayFormat(job.endDate)
                        }})
                    </h4>
                    <p>{{ job.location }} | {{ job.employmentType }}</p>
                    <p>{{ job.summary }}</p>
                    <p v-if="job.responsibilities && job.responsibilities.length > 0">
                        <strong>Responsibilities:</strong>
                    <ul>
                        <li v-for="responsibility in job.responsibilities" :key="responsibility">{{ responsibility }}
                        </li>
                    </ul>
                    </p>
                    <p v-if="job.achievements && job.achievements.length > 0">
                        <strong>Achievements:</strong>
                    <ul>
                        <li v-for="achievement in job.achievements" :key="achievement">{{ achievement }}</li>
                    </ul>
                    </p>
                    <p v-if="job.techStack && job.techStack.length > 0">
                        <strong>Tech Stack:</strong>
                        <BadgeListComponent :items="job.techStack" />
                    </p>

                </div>
            </div>
            <hr />

            <!-- Education -->
            <div class="education pagebreakavoidinside">
                <h3>Education</h3>
                <div v-for="edu in resumeData.education" :key="edu.institutionName"
                    class="education-item pagebreakavoidinside">
                    <h4><strong>{{ edu.institutionName }}</strong> ({{ toDisplayFormat(edu.startDate) }} - {{
                        toDisplayFormat(edu.endDate) || 'Ongoing' }})</h4>
                    <p>{{ edu.fieldOfStudy }} | {{ edu.location }}</p>
                    <p>{{ edu.summary }}</p>
                    <p v-if="edu.skills && edu.skills.length > 0">
                        <strong>Relevant Skills:</strong>
                        <BadgeListComponent :items="edu.skills" />
                    </p>
                </div>
            </div>
            <hr />

            <!-- Certifications -->
            <div class="certifications">
                <h3>Certifications</h3>
                <div v-for="cert in resumeData.certifications" :key="cert.credentialId"
                    class="certification pagebreakavoidinside">
                    <h4><strong>{{ cert.title }}</strong> ({{ toDisplayFormat(cert.startDate) }} - {{
                        toDisplayFormat(cert.endDate) || 'Ongoing' }})</h4>
                    <p>{{ cert.organization }}</p>
                    <p>Credential ID: {{ cert.credentialId }}</p>
                    <p><a :href="cert.credentialLink">View Credential</a></p>
                    <p><strong>Relevant Skills:</strong>
                        <BadgeListComponent :items="cert.skills" />
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import html2pdf from 'html2pdf.js';  // Import the html2pdf.js library
import BadgeListComponent from "@/components/BadgeListComponent.vue";

export default {
    name: "PreviewResumeComponent",
    components: {
        BadgeListComponent,
    },
    props: {
        resumeData: {
            type: Object,
            required: true,
        },
    },
    methods: {
        toDisplayFormat(date) {
            if (!date) {
                return "";
            }
            const [year, month] = date.split("-");
            return `${month}/${year}`;
        },
        generatePDF() {
            const resumeElement = this.$refs.resumeContent;

            // Use html2pdf.js to generate the PDF from the HTML content
            const options = {
                filename: this.resumeData.userMetadata.fullName + ' Resume.pdf',  // The name of the output PDF file
                html2canvas: {
                    scale: 4,  // Increases the quality of the PDF (optional, adjust as needed)
                    useCORS: true, // Allow cross-origin images
                },
                jsPDF: {
                    unit: 'mm',
                    format: 'a4',
                    orientation: 'portrait',
                },
            };

            // Generate the PDF
            html2pdf().from(resumeElement).set(options).save();
        },
    },
};
</script>

<style scoped>
.resume-container {
    width: 100%;
    max-width: 800px;
    margin: auto;
    background: white;
    padding: 40px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    font-family: Arial, sans-serif;
    word-break: break-all;
    word-wrap: break-word;
    /* Makes content scrollable */
}

/* Prevent content from breaking after a section */
h3,
section {
    page-break-after: avoid;
}

.pagebreakavoidinside {
    page-break-inside: avoid;
}

.resume-header {
    padding-bottom: 15px;
    margin-bottom: 20px;
}

.profile-img {
    width: 169px;
    height: 169px;
    border-radius: 50%;
    margin-right: 20px;
}

.contact-info,
.skills,
.work-experience,
.education,
.education-item,
.job,
.certifications {
    margin-bottom: 20px;
}

.contact-info {
    margin: auto;
    /* Centers both vertically and horizontally within the parent */
    display: flex;
    /* Enables Flexbox */
    flex-direction: column;
    /* Ensures items stack vertically */
    justify-content: center;
    /* Centers items vertically */
    align-items: flex-start;
    /* Aligns items to the left (change to `center` if you want center alignment horizontally) */
    height: 100%;
    /* Ensure the div has a height defined, or use `min-height` */
    text-align: left;
    /* Makes sure the text stays aligned to the left */
}
</style>