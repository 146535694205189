<template>
  <div class="certifications">
    <h4>Certifications</h4>
    <div v-for="(cert, index) in certificationsCopy" :key="index" class="mb-4">
      <h5>Certification {{ index + 1 }}</h5>
      
      <!-- Title and Organization fields -->
      <div class="mb-3">
        <label for="title" class="form-label">Title</label>
        <input type="text" class="form-control" :id="'title' + index" v-model="cert.title" @input="updateCertifications" />
      </div>
      <div class="mb-3">
        <label for="organization" class="form-label">Organization</label>
        <input type="text" class="form-control" :id="'organization' + index" v-model="cert.organization" @input="updateCertifications" />
      </div>

      <!-- Start Date -->
      <div class="row">
        <div class="col-md-6 mb-3">
          <label for="startDate" class="form-label">Start Date</label>
          <!-- Month Dropdown -->
          <select class="form-control" v-model="cert.startMonth" @change="updateCertifications">
            <option v-for="(month, index) in months" :key="index" :value="index + 1">
              {{ month }}
            </option>
          </select>
        </div>

        <div class="col-md-6 mb-3">
          <label for="startYear" class="form-label">Start Year</label>
          <!-- Year Dropdown -->
          <select class="form-control" v-model="cert.startYear" @change="updateCertifications">
            <option v-for="(year, index) in years" :key="index" :value="year">
              {{ year }}
            </option>
          </select>
        </div>
      </div>

      <!-- End Date -->
      <div class="row" v-if="cert.endYear">
        <div class="col-md-6 mb-3">
          <label for="endDate" class="form-label">End Date</label>
          <!-- Month Dropdown -->
          <select class="form-control" v-model="cert.endMonth" @change="updateCertifications">
            <option v-for="(month, index) in months" :key="index" :value="index + 1">
              {{ month }}
            </option>
          </select>
        </div>

        <div class="col-md-6 mb-3">
          <label for="endYear" class="form-label">End Year</label>
          <!-- Year Dropdown -->
          <select class="form-control" v-model="cert.endYear" @change="updateCertifications">
            <option v-for="(year, index) in years" :key="index" :value="year">
              {{ year }}
            </option>
          </select>
        </div>
      </div>

      <!-- Credential ID -->
      <div class="mb-3">
        <label for="credentialId" class="form-label">Credential ID</label>
        <input type="text" class="form-control" :id="'credentialId' + index" v-model="cert.credentialId" @input="updateCertifications" />
      </div>

      <!-- Credential Link -->
      <div class="mb-3">
        <label for="credentialLink" class="form-label">Credential Link</label>
        <input type="text" class="form-control" :id="'credentialLink' + index" v-model="cert.credentialLink" @input="updateCertifications" />
      </div>

      <!-- Skills (list editor) -->
      <div class="mb-3">
        <SkillsComponent v-model="cert.skills" title="Relevant Skills" placeholder="Add a new acquired skill" />
      </div>      

      <!-- Remove Certification -->
      <button class="btn btn-danger btn-sm" @click="removeCertification(index)">Remove</button>
    </div>

    <!-- Add Certification -->
    <button class="btn btn-primary" @click="addCertification">Add Certification</button>
  </div>
</template>

<script>
import SkillsComponent from "./SkillsComponent.vue";

export default {
  name: "CertificationComponent",
  props: {
    certifications: Array,
  },
  components:{
    SkillsComponent
  },
  data() {
    return {
      months: [
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'
      ],
      years: Array.from({ length: 100 }, (v, k) => new Date().getFullYear() - k),

      certificationsCopy: this.certifications.map(cert => ({
      ...cert,
      startMonth: cert.startDate ? parseInt(cert.startDate.split('-')[1]) : null,
      startYear: cert.startDate ? parseInt(cert.startDate.split('-')[0]) : null,
      endMonth: cert.endDate ? parseInt(cert.endDate.split('-')[1]) : null,
      endYear: cert.endDate ? parseInt(cert.endDate.split('-')[0]) : null,
    })),
    };
  },
  methods: {
    updateCertifications() {
      this.certificationsCopy.forEach(cert => {
        if (cert.startMonth && cert.startYear) {
          cert.startDate = `${cert.startYear}-${cert.startMonth < 10 ? '0' + cert.startMonth : cert.startMonth}`;
        }
        if (cert.endMonth && cert.endYear) {
          cert.endDate = `${cert.endYear}-${cert.endMonth < 10 ? '0' + cert.endMonth : cert.endMonth}`;
        }
      });

      this.$emit("update-certifications", [...this.certificationsCopy]);
    },
    addCertification() {
      this.certificationsCopy.push({
        title: "",
        organization: "",
        startMonth: null,
        startYear: null,
        endMonth: null,
        endYear: null,
        credentialId: "",
        credentialLink: "",
        skills: [""],
      });
      this.updateCertifications();
    },
    removeCertification(index) {
      this.certificationsCopy.splice(index, 1);
      this.updateCertifications();
    },
    addSkill(index) {
      this.certificationsCopy[index].skills.push("");
      this.updateCertifications();
    },
    removeSkill(index, sIndex) {
      this.certificationsCopy[index].skills.splice(sIndex, 1);
      this.updateCertifications();
    },
  },
};
</script>
