<template>
  <div class="edit-resume light-background-box vh90scrollable">
    <h1>Edit Resume Content</h1>
    <hr />
    <UserMetadataComponent :userMetadata="editableResume.userMetadata" @update-user-metadata="updateUserMetadata" />
    <SkillsComponent v-model="editableResume.skills" title="Skills showcase" placeholder="Add a skills" />
    <hr />

    <WorkExperienceComponent :workExperience="editableResume.workExperience"
      @update-work-experience="updateWorkExperience" />
    <EducationComponent :education="editableResume.education" @update-education="updateEducation" />
    <CertificationComponent :certifications="editableResume.certifications"
      @update-certifications="updateCertifications" />
  </div>
</template>

<script>
import UserMetadataComponent from "./UserMetadataComponent.vue";
import WorkExperienceComponent from "./WorkExperienceComponent.vue";
import EducationComponent from "./EducationComponent.vue";
import CertificationComponent from "./CertificationComponent.vue";
import SkillsComponent from "./SkillsComponent.vue"; // Import the Skills component

export default {
  name: "EditResumeComponent",
  components: {
    UserMetadataComponent,
    WorkExperienceComponent,
    EducationComponent,
    CertificationComponent,
    SkillsComponent, // Register the Skills component
  },
  props: {
    resumeData: Object,
  },
  data() {
    return {
      editableResume: { ...this.resumeData },
    };
  },
  watch: {
    // Ensure local copy syncs with external prop changes
    resumeData: {
      handler(newResume) {
        this.editableResume = { ...newResume };
      },
      deep: true,
    },
  },
  methods: {
    updateUserMetadata(updatedMetadata) {
      this.editableResume.userMetadata = updatedMetadata;
      this.emitUpdatedResume();
    },
    updateWorkExperience(updatedWorkExperience) {
      this.editableResume.workExperience = updatedWorkExperience;
      this.emitUpdatedResume();
    },
    updateEducation(updatedEducation) {
      this.editableResume.education = updatedEducation;
      this.emitUpdatedResume();
    },
    updateCertifications(updatedCertifications) {
      this.editableResume.certifications = updatedCertifications;
      this.emitUpdatedResume();
    },
    updateSkills(updatedSkills) {
      this.editableResume.skills = updatedSkills;
      this.emitUpdatedResume();
    },
    emitUpdatedResume() {
      this.$emit("update-resume", { ...this.editableResume });
    },
  },
};
</script>