<template>
  <div class="work-experience">
    <h4>Work Experience</h4>
    <div v-for="(work, index) in workExperienceCopy" :key="index" class="mb-4">
      <h5>Job {{ index + 1 }}</h5>

      <!-- Company, Location, and Employment Type on the same row -->
      <div class="row">
        <div class="col-md-6 mb-3">
          <label for="company" class="form-label">Company</label>
          <input type="text" class="form-control" :id="'company' + index" v-model="work.companyName"
            @input="updateWorkExperience" />
        </div>

        <div class="col-md-6 mb-3">
          <label for="location" class="form-label">Location</label>
          <input type="text" class="form-control" :id="'location' + index" v-model="work.location"
            @input="updateWorkExperience" />
        </div>
      </div>

      <div class="row">
        <div class="col-md-6 mb-3">
          <label for="employmentType" class="form-label">Employment Type</label>
          <input type="text" class="form-control" :id="'employmentType' + index" v-model="work.employmentType"
            @input="updateWorkExperience" />
        </div>
        <div class="col-md-6 mb-3">
          <label for="isActivePosition" class="form-label">Active Position</label>
          <div class="form-check">
            <input type="checkbox" class="form-check-input" :id="'isActivePosition' + index"
              v-model="work.isActivePosition" @change="isActivePositionChanged" />
            <label :for="'isActivePosition' + index" class="form-check-label">
              {{ work.isActivePosition ? "Yes, this is an active position" : "No, this is not an active position" }}
            </label>
          </div>
        </div>
      </div>

      <!-- Start Date -->
      <div class="row">
        <div class="col-md-6 mb-3">
          <label for="startDate" class="form-label">Start Date</label>
          <!-- Month Dropdown -->
          <select class="form-control" v-model="work.startMonth" @change="updateWorkExperience">
            <option v-for="(month, index) in months" :key="index" :value="index + 1">
              {{ month }}
            </option>
          </select>
        </div>
        <div class="col-md-6 mb-3">
          <label for="startYear" class="form-label">Start Year</label>
          <!-- Year Dropdown -->
          <select class="form-control" v-model="work.startYear" @change="updateWorkExperience">
            <option v-for="(year, index) in years" :key="index" :value="year">
              {{ year }}
            </option>
          </select>
        </div>
      </div>

      <!-- End Date (only if the position is not active) -->
      <div class="row" v-if="!work.isActivePosition">
        <div class="col-md-6 mb-3">
          <label for="endDate" class="form-label">End Date</label>
          <!-- Month Dropdown -->
          <select class="form-control" v-model="work.endMonth" @change="updateWorkExperience">
            <option v-for="(month, index) in months" :key="index" :value="index + 1">
              {{ month }}
            </option>
          </select>
        </div>

        <div class="col-md-6 mb-3">
          <label for="endYear" class="form-label">End Year</label>
          <!-- Year Dropdown -->
          <select class="form-control" v-model="work.endYear" @change="updateWorkExperience">
            <option v-for="(year, index) in years" :key="index" :value="year">
              {{ year }}
            </option>
          </select>
        </div>
      </div>

      <!-- Summary -->
      <div class="mb-3">
        <label for="summary" class="form-label">Summary</label>
        <textarea class="form-control" :id="'summary' + index" v-model="work.summary"
          @input="updateWorkExperience"></textarea>
      </div>

      <!-- Responsibilities (list editor) -->
      <div class="mb-3">
        <label for="responsibilities" class="form-label">Responsibilities</label>
        <ul class="list-group">
          <li class="list-group-item d-flex justify-content-between align-items-center"
            v-for="(responsibility, rIndex) in work.responsibilities" :key="'responsibility-' + rIndex">
            <input type="text" class="form-control" v-model="work.responsibilities[rIndex]"
              @input="updateWorkExperience" />
            <button type="button" class="btn btn-danger btn-sm" @click="removeResponsibility(index, rIndex)">
              Remove
            </button>
          </li>
        </ul>
        <button type="button" class="btn btn-secondary btn-sm mt-2" @click="addResponsibility(index)">Add
          Responsibility</button>
      </div>

      <!-- Achievements (list editor) -->
      <div class="mb-3">
        <label for="achievements" class="form-label">Achievements</label>
        <ul class="list-group">
          <li class="list-group-item d-flex justify-content-between align-items-center"
            v-for="(achievement, aIndex) in work.achievements" :key="'achievement-' + aIndex">
            <input type="text" class="form-control" v-model="work.achievements[aIndex]" @input="updateWorkExperience" />
            <button type="button" class="btn btn-danger btn-sm"
              @click="removeAchievement(index, aIndex)">Remove</button>
          </li>
        </ul>
        <button type="button" class="btn btn-secondary btn-sm mt-2" @click="addAchievement(index)">Add
          Achievement</button>
      </div>

      <!-- Tech Stack -->
      <div class="mb-3">
        <SkillsComponent v-model="work.techStack" title="Tech Stack" placeholder="Add a new tech" />
      </div>
      <button class="btn btn-danger btn-sm" @click="removeWorkExperience(index)">Remove</button>
    </div>

    <button class="btn btn-primary" @click="addWorkExperience">Add Work Experience</button>
  </div>
</template>

<script>
import SkillsComponent from "./SkillsComponent.vue";

export default {
  name: "WorkExperienceComponent",
  components: {
    SkillsComponent,
  },
  props: {
    workExperience: Array,
  },
  data() {
    return {
      months: [
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'
      ],
      years: Array.from({ length: 100 }, (v, k) => new Date().getFullYear() - k),
      workExperienceCopy: this.workExperience.map(work => ({
      ...work,
      startMonth: work.startDate ? parseInt(work.startDate.split('-')[1]) : null,
      startYear: work.startDate ? parseInt(work.startDate.split('-')[0]) : null,
      endMonth: work.endDate ? parseInt(work.endDate.split('-')[1]) : null,
      endYear: work.endDate ? parseInt(work.endDate.split('-')[0]) : null,
    })),
  };
  },
  methods: {
    isActivePositionChanged() {
      this.updateWorkExperience();
    },
    reorderWorkExperiences() {
      this.workExperienceCopy.sort((a, b) => {
        if (a.isActivePosition !== b.isActivePosition) {
          return b.isActivePosition - a.isActivePosition;
        }
        const dateA = a.endDate ? new Date(a.endDate) : null;
        const dateB = b.endDate ? new Date(b.endDate) : null;
        if (dateA && dateB) {
          return dateB - dateA;
        } else if (dateA || dateB) {
          return dateB ? 1 : -1;
        }
        const startDateA = new Date(a.startDate);
        const startDateB = new Date(b.startDate);
        if (startDateA && startDateB) {
          return startDateB - startDateA;
        }
        return a.companyName.localeCompare(b.companyName);
      });
    },
    updateWorkExperience() {
      // Combine selected month and year into a string format 'yyyy-mm'
      this.workExperienceCopy.forEach(work => {
        if (work.startMonth && work.startYear) {
          work.startDate = `${work.startYear}-${work.startMonth < 10 ? '0' + work.startMonth : work.startMonth}`;
        }
        if (work.endMonth && work.endYear) {
          work.endDate = `${work.endYear}-${work.endMonth < 10 ? '0' + work.endMonth : work.endMonth}`;
        }
      });

      this.reorderWorkExperiences();
      this.$emit("update-work-experience", [...this.workExperienceCopy]);
    },
    addWorkExperience() {
      this.workExperienceCopy.push({
        companyName: "",
        location: "",
        employmentType: "",
        startDate: "",
        endDate: "",
        summary: "",
        responsibilities: [""],
        achievements: [""],
        techStack: "",
      });
      this.updateWorkExperience();
    },
    removeWorkExperience(index) {
      this.workExperienceCopy.splice(index, 1);
      this.updateWorkExperience();
    },
    addResponsibility(index) {
      this.workExperienceCopy[index].responsibilities.push("");
      this.updateWorkExperience();
    },
    removeResponsibility(index, rIndex) {
      this.workExperienceCopy[index].responsibilities.splice(rIndex, 1);
      this.updateWorkExperience();
    },
    addAchievement(index) {
      this.workExperienceCopy[index].achievements.push("");
      this.updateWorkExperience();
    },
    removeAchievement(index, aIndex) {
      this.workExperienceCopy[index].achievements.splice(aIndex, 1);
      this.updateWorkExperience();
    },
  },
};
</script>
